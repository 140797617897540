import React, {Component} from 'react';
import {BrowserRouter} from "react-router-dom";
import Switch from "../Router";
import {DimensionContext, dimension} from "./dimension-context";
import {SSRContext} from "./ssr-context";
// import Menu from "../Menu";
import loadable from "@loadable/component";

const Menu = loadable(() => import("../Menu"));

class Index extends Component {

    constructor() {
        super();
        this.state = {dimension, isSSR: true};
    }

    toggleSSR = () => {
        this.setState({isSSR: false})
    }

    componentDidMount() {
        this.setBodyHeight()
        this.dimensionEventListener()
        // setTimeout(() => {
        //     const source = new EventSource("/events/meta")
        //     source.onmessage = (message => {
        //         console.log('Got');
        //         console.log(message.data);
        //     })
        //     // source.onerror = (ev => {
        //     //     console.error(ev)
        //     // })
        // }, 7272)
    }

    setBodyHeight() {
        document.getElementsByTagName("BODY")[0].style.height = this.state.dimension.height + 'px';
    }

    dimensionEventListener() {
        window.addEventListener('resize', () => {
            this.setState({
                    dimension: {
                        height: window.innerHeight,
                        width: window.innerWidth,
                        vertical: window.innerWidth > window.innerHeight
                    }
                },
                this.setBodyHeight
            )
        })
    }

    render() {
        return <BrowserRouter>
            <DimensionContext.Provider value={this.state.dimension}>
                <SSRContext.Provider value={{isSSR: this.state.isSSR, toggleSSR: this.toggleSSR}}>
                    <Switch/>
                </SSRContext.Provider>
                <Menu/>
            </DimensionContext.Provider>
        </BrowserRouter>
    }
}

export default Index;
