import React from "react";
import {Route, Switch, useLocation} from "react-router-dom";
import {TransitionGroup, CSSTransition} from "react-transition-group";
import './router.scss';
import loadable from "@loadable/component";

const HomePage = loadable(() => import("../../Pages/Home"));
const Blog = loadable(() => import("../../Pages/Blog"));
const Single = loadable(() => import("../../Pages/Single"));
const Search = loadable(() => import("../../Pages/Search"));
const ContactPage = loadable(() => import("../../Pages/Contact"));
const NotFound = loadable(() => import("../../Pages/404"));

function Router() {
    let location = useLocation();
    return <TransitionGroup>
        <CSSTransition key={location.key} classNames="fade" timeout={313}>
            <Switch location={location}>
                <Route exact path="/blog" component={Blog}/>
                <Route exact path="/categories/:id/:category" component={Blog}/>
                <Route exact path="/categories" component={Blog}/>
                <Route exact path="/tags/:id/:tag" component={Blog}/>
                <Route exact path="/tags" component={Blog}/>
                <Route exact path="/posts/:id/:slug" component={Single}/>
                <Route exact path="/search" component={Search}/>
                <Route exact path="/contact" component={ContactPage}/>
                <Route exact path="/" component={HomePage}/>
                <Route component={NotFound}/>
            </Switch></CSSTransition></TransitionGroup>
}

export default Router